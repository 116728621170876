import axios from 'axios';
import * as df from 'date-fns';

import { apiRoot } from '../../../../../infrastructure/api';
import securedAxios, {
  newSecuredAxios,
} from '../../../../../utils/secured-axios';

import * as IBooks from '../../../../../interfaces/merchants/resources/books';
import * as IInputs from '../../../../../interfaces/merchants/resources/books/inputs';
import * as IReserve from '../../../../../interfaces/reservations';

import { MerchantBookingPageApiResponse } from '../../../../../interfaces/merchants/bookings';
import {
  MerchantBookingAvailableDatesData,
  MerchantBookingAvailableTimesData,
} from '../../../../../interfaces/merchants/bookings/availabilities';
import { MerchantBookingConfirmData } from '../../../../../interfaces/merchants/bookings/confirm';
import {
  MerchantBookingCourseData,
  MerchantBookingSelectedCourseData,
} from '../../../../../interfaces/merchants/bookings/courses';
import { MerchantBookingCustomFieldsData } from '../../../../../interfaces/merchants/bookings/custom_fields';
import { MerchantBookingEventData } from '../../../../../interfaces/merchants/bookings/event';
import { MerchantBookingEventsData } from '../../../../../interfaces/merchants/bookings/events';
import { MerchantBookingLessonData } from '../../../../../interfaces/merchants/bookings/lessons';
import {
  MerchantBookingSelectedStaffApiResponse,
  MerchantBookingStaffApiResponse,
} from '../../../../../interfaces/merchants/bookings/staff';
import {
  MerchantBookingTimeSlotData,
  MerchantBookingTimeSlotsData,
} from '../../../../../interfaces/merchants/bookings/time_slots';

import * as b from './apiBodyBuilder';
import { isJCBBrand, invalidCardBrandError } from '../../../../../utils/stripe';
import {
  FinishedStatus,
  InputPaymentMethod,
  PaymentMethod,
  ReservationResponseError,
} from '../../../../../interfaces/reservations';

export interface CalendarQuery {
  merchantId: string;
  resourceId: string;
  course: IBooks.Course;
  options: IBooks.Option[];
  staff?: IBooks.Staff;
  start: Date;
  end: Date;
}

export interface DateTimePickerQuery {
  merchantId: string;
  resourceId: string;
  lesson?: IBooks.Lesson;
  start: Date | null;
  end: Date | null;
}

export interface AvailableDateTimeQuery {
  merchantId: string;
  resourceId: string;
  course: IBooks.Course;
  options: IBooks.Option[];
  staff?: IBooks.Staff;
  date: Date;
}

interface PostPaymentResult {
  error?: stripe.StripeError;
  payment: IInputs.Payment;
}

/** @deprecated SSRでのリクエスト時にセッション情報がのらない */
export const fetchResource = async (merchantId: string, resourceId: string) => {
  const { data } = await securedAxios.get<MerchantBookingPageApiResponse>(
    `${apiRoot()}/merchants/${merchantId}/booking_pages/${resourceId}?formatted=true`,
  );
  return data;
};

/** @deprecated SSRでのリクエスト時にセッション情報がのらない */
export const fetchCourse = async (merchantId: string, resourceId: string) => {
  const { data } = await securedAxios.get<MerchantBookingCourseData>(
    `${apiRoot()}/merchants/${merchantId}/booking_pages/${resourceId}/courses`,
  );
  return data;
};

export const fetchSelectedCourse = async (
  merchantId: string,
  resourceId: string,
  CourseId: string,
): Promise<MerchantBookingSelectedCourseData | undefined> => {
  try {
    const { data } = await securedAxios.get<MerchantBookingSelectedCourseData>(
      `${apiRoot()}/merchants/${merchantId}/booking_pages/${resourceId}/courses/${CourseId}`,
    );
    return data;
  } catch (e) {
    if (axios.isAxiosError(e) && e.response && e.response.status < 500) {
      return;
    }
    throw e;
  }
};

/** @deprecated SSRでのリクエスト時にセッション情報がのらない */
export const fetchLesson = async (merchantId: string, resourceId: string) => {
  const { data } = await securedAxios.get<MerchantBookingLessonData>(
    `${apiRoot()}/merchants/${merchantId}/booking_pages/${resourceId}/lessons`,
  );
  return data;
};

/** @deprecated SSRでのリクエスト時にセッション情報がのらない */
export const fetchStaff = async (merchantId: string, resourceId: string) => {
  const { data } = await securedAxios.get<MerchantBookingStaffApiResponse>(
    `${apiRoot()}/merchants/${merchantId}/booking_pages/${resourceId}/staff`,
  );
  return data;
};

export const fetchSelectedStaff = async (
  merchantId: string,
  resourceId: string,
  staffId: string,
): Promise<MerchantBookingSelectedStaffApiResponse | undefined> => {
  try {
    const { data } =
      await securedAxios.get<MerchantBookingSelectedStaffApiResponse>(
        `${apiRoot()}/merchants/${merchantId}/booking_pages/${resourceId}/staff/${staffId}`,
      );
    return data;
  } catch (e) {
    if (axios.isAxiosError(e) && e.response && e.response.status < 500) {
      return;
    }
    throw e;
  }
};

const FORMAT = 'YYYYMMDDHHmm'; // 201811252001 2018/11/25 20:01

const toCalendarParam = (q: CalendarQuery): string => {
  const query = new URLSearchParams({
    course_id: q.course.id.toString(),
    start: df.format(q.start, FORMAT),
    end: df.format(q.end, FORMAT),
  });
  q.options.map((o) => query.append('option_ids[]', o.id.toString()));
  if (q.staff) query.append('staff_id', q.staff.id.toString());
  return query.toString();
};

export const fetchEvents = async (q: CalendarQuery) => {
  const url = new URL(
    `${apiRoot()}/merchants/${q.merchantId}/booking_pages/${
      q.resourceId
    }/events`,
  );
  url.search = toCalendarParam(q);
  const { data } = await securedAxios.get<MerchantBookingEventsData>(
    url.toString(),
  );
  return data;
};

const toDatePickerParam = (q: DateTimePickerQuery): string => {
  const query = new URLSearchParams();
  if (!!q.lesson) query.append('lesson_id', q.lesson.id.toString());
  if (!!q.start) query.append('start', df.format(q.start, FORMAT));
  if (!!q.end) query.append('end', df.format(q.end, FORMAT));
  return query.toString();
};

export const fetchTimeSlots = async (
  q: DateTimePickerQuery,
): Promise<MerchantBookingTimeSlotsData> => {
  try {
    const url = new URL(
      `${apiRoot()}/merchants/${q.merchantId}/booking_pages/${
        q.resourceId
      }/time_slots`,
    );
    url.search = toDatePickerParam(q);

    const { data } = await securedAxios.get<MerchantBookingTimeSlotsData>(
      url.toString(),
    );
    return data;
  } catch (e) {
    // ローディングがスタックしない様に空で返す。
    // TODO: 予約カレンダーでリロードを行う場合、要修正。
    return {
      meta: {
        staff: undefined,
        waiting_list_provided: false,
        waiting_listed: [],
      },
      data: [],
    };
  }
};

const toAvailableDateTimeParam = (q: AvailableDateTimeQuery): string => {
  const query = new URLSearchParams({
    course_id: q.course.id.toString(),
    date: df.format(q.date, FORMAT),
  });

  q.options.map((o) => query.append('option_ids[]', o.id.toString()));
  if (q.staff) query.append('staff_id', q.staff.id.toString());
  return query.toString();
};

export const fetchAvailableDates = async (
  q: AvailableDateTimeQuery,
): Promise<MerchantBookingAvailableDatesData> => {
  const url = new URL(
    `${apiRoot()}/merchants/${q.merchantId}/booking_pages/${
      q.resourceId
    }/available_dates`,
  );
  url.search = toAvailableDateTimeParam(q);

  const { data } = await securedAxios.get<MerchantBookingAvailableDatesData>(
    url.toString(),
  );
  return data;
};

export const fetchAvailableTimes = async (
  q: AvailableDateTimeQuery,
): Promise<MerchantBookingAvailableTimesData> => {
  const url = new URL(
    `${apiRoot()}/merchants/${q.merchantId}/booking_pages/${
      q.resourceId
    }/available_times`,
  );
  url.search = toAvailableDateTimeParam(q);

  const { data } = await securedAxios.get<MerchantBookingAvailableTimesData>(
    url.toString(),
  );

  return data;
};

export const addToWaitingList = async (
  merchantPublicId: string,
  resourceId: string,
  timeSlotId: number,
  lastName?: string,
  firstName?: string,
  email?: string,
): Promise<IReserve.WaitingListResponseData> => {
  try {
    const client = newSecuredAxios({
      merchantPublicId,
    });
    const { data } = await client.post<
      {
        resource_id: string;
        time_slot_id: number;
        last_name?: string;
        first_name?: string;
        email?: string;
      },
      { data: IReserve.WaitingListResponseData }
    >(`${apiRoot()}/reservations/waiting_list`, {
      resource_id: resourceId,
      time_slot_id: timeSlotId,
      last_name: lastName,
      first_name: firstName,
      email,
    });
    return data;
  } catch (e) {
    if (axios.isAxiosError(e) && e.response && e.response.status === 400) {
      return e.response.data;
    }
  }
  return { success: false };
};

export const fetchCustomFields = async (
  merchantId: string,
  resourceId: string,
) => {
  const { data } = await securedAxios.get<MerchantBookingCustomFieldsData>(
    `${apiRoot()}/merchants/${merchantId}/booking_pages/${resourceId}/custom_fields`,
  );
  return data;
};

export const fetchConfirmation = async (
  merchantId: string,
  resourceId: string,
) => {
  const { data } = await securedAxios.get<MerchantBookingConfirmData>(
    `${apiRoot()}/merchants/${merchantId}/booking_pages/${resourceId}/confirm`,
  );
  return data;
};

export const fetchEvent = async (merchantId: string, resourceId: string) => {
  const { data } = await securedAxios.get<MerchantBookingEventData>(
    `${apiRoot()}/merchants/${merchantId}/booking_pages/${resourceId}/event`,
  );
  return data;
};

export const fetchSelectedTimeSlot = async (
  merchantId: string,
  resourceId: string,
  timeSlotId: string,
): Promise<MerchantBookingTimeSlotData | undefined> => {
  try {
    const { data } = await securedAxios.get<MerchantBookingTimeSlotData>(
      `${apiRoot()}/merchants/${merchantId}/booking_pages/${resourceId}/time_slots/${timeSlotId}`,
    );
    return data;
  } catch (e) {
    if (axios.isAxiosError(e) && e.response && e.response.status < 500) {
      return;
    }
    throw e;
  }
};

export const fetchReservationPaymentMethods = async (
  merchantPublicId: string,
  reservationId: number,
) => {
  const client = newSecuredAxios({
    merchantPublicId,
  });
  const { data } = await client.get<IReserve.ReservationsPaymentMethodsData>(
    `${apiRoot()}/reservations/${reservationId}/payment_methods`,
  );
  return data;
};

const inputsToPostStructure = (
  inputs: IInputs.State,
  options?: { email: string },
): IReserve.Input => {
  if (inputs.course) {
    return inputs.staff
      ? {
          course_id: inputs.course.id.toString(),
          option_ids: inputs.options!.map((o) => o.id.toString()),
          staff_id: inputs.staff.id.toString(),
        }
      : inputs.options
      ? {
          course_id: inputs.course.id.toString(),
          option_ids: inputs.options!.map((o) => o.id.toString()),
        }
      : { course_id: inputs.course.id.toString() };
  }
  if (inputs.lesson) {
    return {
      lesson_id: inputs.lesson.id.toString(),
    };
  }
  if (inputs.eventTimeSlot) {
    return b.withEventTimeSlot(inputs.eventTimeSlot);
  }
  if (inputs.timeSlotAvailability) {
    return b.withTimeSlotAvailability(inputs.timeSlotAvailability);
  }
  if (inputs.schoolTimeSlots) {
    return b.withSchoolTimeSlot(inputs.schoolTimeSlots);
  }
  if (inputs.userInfo) {
    return b.withUserInfo(inputs.userInfo);
  }
  if (inputs.payment) {
    return b.withPayment(inputs.payment, { email: options?.email ?? '' });
  }
  if (inputs.termsAccepted || inputs.selectedPaymentMethod) {
    return b.withFinalize(inputs.termsAccepted, inputs.selectedPaymentMethod);
  }
  return {};
};

const bookingFirstStepUrl = (
  modelType: IBooks.ModelType,
  reservationId: string,
): string => {
  if (modelType === IBooks.ModelType.EventScheme) {
    return `${apiRoot()}/reservations/${reservationId}/time_slots`;
  } else if (modelType === IBooks.ModelType.SchoolScheme) {
    return `${apiRoot()}/reservations/${reservationId}/lesson`;
  } else {
    return `${apiRoot()}/reservations/${reservationId}/course`;
  }
};

const _postReservations = async (
  url: string,
  reqData: IReserve.ReservationsRequestType,
  merchantPublicId: string,
): Promise<IReserve.ReservationsResponseData> => {
  try {
    const client = newSecuredAxios({
      merchantPublicId,
    });
    const { data } = await client.post<
      IReserve.ReservationsRequestType,
      { data: IReserve.ReservationsResponseData }
    >(url, reqData);
    return data;
  } catch (e) {
    if (axios.isAxiosError(e) && e.response) {
      if (e.response.status === 400) {
        return { ...e.response.data, errorCode: e.response.status };
      } else {
        return { success: false, errorCode: e.response.status };
      }
    }
    throw e;
  }
};

export const postReservationsStart = async (
  modelType: IBooks.ModelType,
  merchantId: string,
  resourceId: string,
  inputs: IInputs.State,
  withExpressFlow: boolean,
  reservationId?: number,
) =>
  reservationId
    ? _postReservations(
        bookingFirstStepUrl(modelType, reservationId.toString()),
        {
          input: await inputsToPostStructure(inputs),
          public_id: merchantId,
        },
        merchantId,
      )
    : _postReservations(
        `${apiRoot()}/reservations`,
        {
          input: await inputsToPostStructure(inputs),
          public_id: merchantId,
          resource_id: resourceId,
          booked_from: withExpressFlow ? 'express' : '',
        },
        merchantId,
      );

export const postReservationsBookingDateTime = async (
  reservationId: number,
  inputs: IInputs.State,
  merchantPublicId: string,
) =>
  _postReservations(
    `${apiRoot()}/reservations/${reservationId}/preferred_datetime`,
    {
      input: await inputsToPostStructure(inputs),
    },
    merchantPublicId,
  );

export const postReservationsTimeSlots = async (
  reservationId: number,
  inputs: IInputs.State,
  merchantPublicId: string,
) =>
  _postReservations(
    `${apiRoot()}/reservations/${reservationId}/time_slots`,
    {
      input: await inputsToPostStructure(inputs),
    },
    merchantPublicId,
  );

export type PostReservationUserInfoErrorType =
  | 'above_reservation_count_limit'
  | 'invalid_input'
  | 'customer_blocking';

type InvalidCustomerInputErrorType = 'invalid' | 'already_taken';

export type InvalidCustomerInputError = {
  field: string;
  type: InvalidCustomerInputErrorType;
};
export interface ReservationsUserInfoResponse {
  success: boolean;
  entered?: {
    reservation_id: number;
    payment_methods: PaymentMethod[];
    finished_status: FinishedStatus;
  };
  errors?: ReservationResponseError;
  errorCode?: number;
}

export const postReservationsUserInfo = async (
  reservationId: number,
  inputs: IInputs.State,
  merchantPublicId: string,
): Promise<{
  data?: ReservationsUserInfoResponse;
  success: boolean;
  errorCode: number;
  errors?: InvalidCustomerInputError[];
  errorType?: PostReservationUserInfoErrorType;
}> => {
  const requestBody: IReserve.Input = {};

  const contact = {};
  if (inputs.userInfo) {
    const { customerFields, surveyAnswer, questionnaires } = inputs.userInfo;

    if (customerFields) {
      Object.keys(customerFields).forEach((k) => {
        contact[k] = customerFields[k];
      });
    }

    requestBody.contact = contact;

    if (surveyAnswer) {
      requestBody.survey_answer = surveyAnswer;
    }

    if (questionnaires) {
      requestBody.questionnaires_attributes = [];

      Object.keys(questionnaires).forEach((id) => {
        const item = b.toQuestionnaireAttributes(
          id,
          b.toQuestionnaireValue(questionnaires[id]),
        );
        if (item) {
          requestBody.questionnaires_attributes?.push(item);
        }
      });
    }
  }

  try {
    const client = newSecuredAxios({
      merchantPublicId,
    });
    const { data } = await client.post<
      IReserve.ReservationsRequestType,
      { data: ReservationsUserInfoResponse }
    >(`${apiRoot()}/reservations/${reservationId}/customer`, {
      input: requestBody,
    });
    return {
      success: true,
      data,
      errorCode: 200,
    };
  } catch (e) {
    if (axios.isAxiosError(e) && e.response) {
      if (e.response.status === 400) {
        return {
          ...e.response.data,
          errorCode: e.response.status,
          errorType: e.response.data.error_type,
        };
      } else {
        return {
          success: false,
          errorCode: e.response.status,
          errorType: e.response.data.error_type,
        };
      }
    }
    throw e;
  }
};

export const postReservationsPayment = async (args: {
  reservationId: number;
  inputs: IInputs.State;
  email: string;
  merchantPublicId: string;
}): Promise<{
  data?: { selected_payment_method: InputPaymentMethod };
  errorType?: string;
}> => {
  const { reservationId, inputs, email, merchantPublicId } = args;
  const input = inputsToPostStructure(inputs, { email });
  try {
    const client = newSecuredAxios({
      merchantPublicId,
    });
    const { data } = await client.post<
      IReserve.ReservationsRequestType,
      { data: { selected_payment_method: InputPaymentMethod } }
    >(`${apiRoot()}/reservations/${reservationId}/payment/validate`, { input });
    return { data };
  } catch (e) {
    if (axios.isAxiosError(e) && e.response) {
      if (e.response.status === 400) {
        return { errorType: e.response.data.error_type };
      } else {
        return { errorType: e.response.data.error_type };
      }
    }
    throw e;
  }
};

export const postReservationsFinish = async (params: {
  reservationId: number;
  inputs: IInputs.State;
  lineAccessToken: string | null;
  merchantPublicId: string;
}) => {
  const { reservationId, inputs, lineAccessToken, merchantPublicId } = params;
  return _postReservations(
    `${apiRoot()}/reservations/${reservationId}/finalize`,
    {
      input: {
        terms_accepted: inputs.termsAccepted,
        payment_method: inputs.selectedPaymentMethod,
        line_access_token: lineAccessToken,
      },
    },
    merchantPublicId,
  );
};

const callStripeApi = (
  payment: IInputs.Payment,
  jcbEnabled: boolean,
): Promise<PostPaymentResult> => {
  return new Promise((resolve) => {
    Stripe.createToken(
      {
        cvc: payment.creditCard!.securityCode,
        exp_month: parseInt(payment.creditCard!.month!, 10),
        exp_year: parseInt(payment.creditCard!.year!, 10),
        number: payment.creditCard!.cardNumber!,
      },
      (status, response) => {
        if (status === 200) {
          const brand = response.card.brand;
          if (isJCBBrand(brand) && !jcbEnabled) {
            resolve({ payment, error: invalidCardBrandError });
          } else {
            resolve({ payment: { ...payment, token: response.id } });
          }
        } else {
          resolve({ payment, error: response.error });
        }
      },
    );
  });
};

export const postPayment = async (
  payment: IInputs.Payment,
  jcbEnabled: boolean,
): Promise<PostPaymentResult> => {
  if (payment.paymentMethod.method !== 'credit_card') {
    return { payment };
  }
  if (!payment.cardSelection) {
    // カード選びがない => 利用歴がなく新しいカード生成
    return callStripeApi(payment, jcbEnabled);
  }
  if (payment.cardSelection === 'used') {
    // カード利用歴があり、以前のカードを利用する
    return { payment };
  }
  if (payment.cardSelection === 'another') {
    // カード利用歴があるが、新しく生成する
    return callStripeApi(payment, jcbEnabled);
  }
  throw new Error(`cannot handle with payment: ${payment}`);
};
