import classNames from 'classnames';
import * as React from 'react';

import css from './Heading.module.scss';

interface Props extends React.HTMLProps<{}> {
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

const Heading = (props: Props) => {
  const { children, className } = props;
  let { tag: Tag } = props;
  Tag = Tag || 'h2';
  const classes = classNames(css.Heading, className);

  return <Tag className={classes}>{children}</Tag>;
};

export default Heading;
